const glow = [
  'relative',
  'before:absolute',
  'before:flex',
  'before:-z-10',
  'before:inset-0',
  'before:blur-3xl',
  'before:bg-gradient-to-r',
  'before:from-rainbow-start',
  'before:via-rainbow-via',
  'before:to-rainbow-end',
  'before:rounded-xl',
  'before:animate-shrow-5',
  'before:animate-infinite'
].join(' ')

export const glowSm = glow.concat(' before:opacity-[.15]')
export const glowLg = glow.concat(' before:opacity-70')

export const lightInset =
  'shadow-[inset_0px_0px_15px_5px_rgba(255,255,255,0.2)]'

export const genericBorder =
  'bg-black border-[0.5px] border-gray-600 rounded-xl'
